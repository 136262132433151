import classNames from 'classnames';
import Typography from 'components/generics/texts/Typography';
import React, { InputHTMLAttributes, useState } from 'react';
import InputMask from 'react-input-mask';
import classes from './index.module.scss';

export interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  noBorder?: boolean;
  errorMessage?: string;
  mask: string;
  maskChar: string;
  placeholder: string;
  placeholderClassName?: string | string[];
  inputClassName?: string;
  withPlaceHolderTransition?: boolean;
  inputStyle?: any;
}

const BaseInput = ({
  mask,
  maskChar,
  placeholder,
  placeholderClassName,
  className,
  onBlur,
  onFocus,
  value,
  withPlaceHolderTransition = false,
  inputStyle,
  ...props
}: BaseInputProps) => {
  const [focused, setFocused] = useState(false);
  const stringVal = value as string;
  const filled = stringVal && stringVal.length > 0;

  return (
    <div className={classNames(classes.formGroup)}>
      <Typography
        className={classNames(
          classes.formLabel,
          placeholderClassName,
          (focused || filled) && classes.focused,
          !withPlaceHolderTransition && classes.hide
        )}
      >
        {placeholder}
      </Typography>
      <InputMask
        mask={mask}
        maskChar={maskChar}
        className={classNames(
          classes.formInput,
          className,
          inputStyle && classes.inputStyle
        )}
        onBlur={(e) => {
          setFocused(false);
          onBlur?.(e);
        }}
        onFocus={(e) => {
          setFocused(true);
          onFocus?.(e);
        }}
        value={value}
        {...props}
      />
    </div>
  );
};

export default BaseInput;
